import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  Table,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Dropzone } from "components";
import { DatePicker } from "components/Datepicker";
import { RadioGroupCustom } from "components/RadioGroupCustom";
import { Tooltip, TooltipError } from "components/Tooltip";
import { BOOLEAN_LABEL } from "constants/labels";
import {
  BooleanEnum,
  ElementTypeEnum,
  GetProviderOrderQuery,
  OrderAfterReceptionEnum,
  OrderStatusEnum,
  SetCachedPvDocument,
  type InputPvType,
  GetCaseQuery,
} from "graphql/__generated__/graphql";
import { useUpload } from "hooks";
import { useTrackEvent } from "libs/tracking";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { RiCloseFill, RiDragDropLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import { useMutation } from "urql";
import { toBase64 } from "utils";
import { PdfView } from "../PdfView";
import { pvSchema } from "../utils";
import { useTranslation } from "react-i18next";

export const AfterReceptionDecisionLabel = [
  { value: OrderAfterReceptionEnum.NoWarning, label: "Réception sans reserve" },
  {
    value: OrderAfterReceptionEnum.WithWarning,
    label: "Réception avec reserve",
  },
  { value: OrderAfterReceptionEnum.Ajourned, label: "Ajournement" },
  { value: OrderAfterReceptionEnum.Rejected, label: "Rejet" },
];

type InputPv = InputPvType & {
  file: { b64: string; fullFile: File };
};

export enum ProjectMarket {
  CANUT = "CENTRALE D'ACHAT DU NUMERIQUE ET DES TELECOMS-AMOE CANUT-Lot 1 - National",
}

export const OrderPvModalForm: React.FC<{
  caseResult: GetCaseQuery["case"];
  order: GetProviderOrderQuery["order"];
  onClose: () => void;
}> = ({ order, caseResult, onClose }) => {
  const [, setCachedPV] = useMutation(SetCachedPvDocument);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { trackEvent } = useTrackEvent();
  const { uploadFile } = useUpload();

  const status = order?.status;
  const endDate = order?.clientOrder?.endDate;
  const project = order?.project;
  const reference = order?.reference;
  const providerOrderId = order?.id;

  const clientOrderReferenceToUGAP =
    order?.clientOrder?.clientOrderReferenceToUGAP;
  const productLines = order?.clientOrder?.productLines || [];

  const singleCase = caseResult;

  const { pv } = singleCase || {};

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<InputPv>({
    defaultValues: {
      deliveryDate: pv?.deliveryDate || endDate,
      afterReceptionDecision: pv?.afterReceptionDecision,
      deliveryDelay: pv?.deliveryDelay,
      reportClient: pv?.reportClient,
      cachet: pv?.cachet,
      signature: pv?.signature,
      nameQuality: pv?.nameQuality,
      partnerComment: pv?.partnerComment,
      file: undefined,
    },
    // @ts-expect-error
    resolver: yupResolver(pvSchema),
  });

  const onSubmit = async (values: InputPv) => {
    await pvSchema.validate(values);
    setIsLoading(true);

    if (!reference) {
      alert(
        t(
          "order_pv_modal_no_reference",
          "Aucune référence de commande n'a été trouvée. Veuillez contacter le support.",
        ),
      );
      setIsLoading(false);

      return;
    }

    let filePath;

    const { file, ...restValues } = values;

    if (file?.fullFile) {
      filePath = await uploadFile(values.file.fullFile, ElementTypeEnum.Pv);
    }
    if (!filePath && !pv?.path && !pv?.attachment) {
      alert("Merci de fournir une pièce jointe.");
      setIsLoading(false);

      return;
    }
    await setCachedPV({
      pv: {
        ...restValues,
        id: caseResult.id,
        providerOrderId,
        isValidated: true,
        reference,
        path: filePath,
      },
    });
    await trackEvent("Submit", "Submit PV", {
      providerOrderId,
      projectType: project?.type,
    });

    setIsLoading(false);

    navigate(`..`);
  };

  const deliveryDelayValue = watch("deliveryDelay");
  const reportClientValue = watch("reportClient");
  const afterReceptionDecisionValue = watch("afterReceptionDecision");
  const cachetValue = watch("cachet");
  const signatureValue = watch("signature");
  const nameQualityValue = watch("nameQuality");
  const fileValue = watch("file");

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-test="pv-modal">
      <Grid templateColumns="repeat(12, 1fr)" gap={5} rowGap={1}>
        <GridItem
          colSpan={12}
          borderBottom={"1px solid #DCDCDC"}
          mb={20}
          bg={"white"}
          h="80px"
          zIndex={"10"}
          pos={"sticky"}
          top={0}
        >
          <Flex justify={"space-between"} align="center" mb={8} h={"100%"}>
            <Flex>
              <Icon
                as={RiCloseFill}
                color="grey"
                fontSize={30}
                mr={8}
                cursor="pointer"
                onClick={onClose}
              />
              <Heading color="neutral.text.black" size={"xl"}>
                {t(
                  "order_pv_modal_title",
                  "PV de la commande n°{{reference}}",
                  {
                    reference: order?.name,
                  },
                )}
              </Heading>
            </Flex>

            <Button
              data-test="save-pv-button"
              type={"submit"}
              isLoading={isLoading}
            >
              {t("order_pv_modal_save", "Sauvegarder mon PV")}
            </Button>
          </Flex>
        </GridItem>
        <GridItem colSpan={6} pos="relative">
          <Flex direction={"column"} alignItems={"start"} gap={4}>
            {pv?.advComment && (
              <Flex>
                <Alert status="error">
                  <AlertIcon />
                  <Box flex="1">
                    <AlertTitle>
                      {t("order_pv_modal_reject_reason", "Motif de rejet")}
                    </AlertTitle>
                    <AlertDescription display="block">
                      {pv?.advComment}
                    </AlertDescription>
                  </Box>
                </Alert>
              </Flex>
            )}

            <FormControl size="sm">
              <FormLabel>
                {t("order_pv_modal_chrono_number", "Numéro d’affaire")}
                <Tooltip
                  label={t(
                    "order_pv_modal_chrono_tooltip",
                    "Cadre réservé au titulaire / Champ N° Chrono",
                  )}
                />
              </FormLabel>
              <Text fontSize="sm">{project?.reference}</Text>
            </FormControl>

            {project?.market !== ProjectMarket.CANUT && (
              < FormControl size="sm">
                <FormLabel>
                  {t(
                    "order_pv_modal_ugap_number",
                    "Numéro de commande UGAP (ARC)",
                  )}
                  <Tooltip
                    label={t(
                      "order_pv_modal_ugap_tooltip",
                      "Cadre réservé au titulaire / N° commande UGAP (ARC)",
                    )}
                  />
                </FormLabel>
                <Text fontSize="sm">{clientOrderReferenceToUGAP}</Text>
              </FormControl>
            )}
            <FormControl size="sm">
              <FormLabel>
                Numéro de commande d'achat {project?.market !== ProjectMarket.CANUT ? "(OS)" : ""}
                <Tooltip label={`Cadre réservé au titulaire / N° commande d’achat ${project?.market !== ProjectMarket.CANUT ? "(OS)" : ""}`} />
              </FormLabel>
              <Text fontSize="sm">{reference}</Text>
            </FormControl>
            <Text size={"sm"} fontWeight="bold">
              {t("order_pv_modal_list_of_uos", "Liste des Unités d'oeuvres")}
              <Tooltip
                label={t(
                  "order_pv_modal_list_of_uos_tooltip",
                  "Cadre réservé au bénéficiaire / Liste des unités d'oeuvres",
                )}
              />
            </Text>
            {productLines?.length ? (
              <Table size={"sm"}>
                <Thead>
                  <Tr>
                    <Th>{t("order_pv_modal_code_uo", "Code UO")}</Th>
                    <Th>{t("order_pv_modal_quantity", "Quantité")}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {productLines?.map((pl) => (
                    <Tr key={pl.id}>
                      <Td>{pl.code}</Td>
                      <Td>{pl.quantity}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            ) : (
              <Text size={"sm"}>
                {t("order_pv_modal_no_uos", "Aucun UO présent")}
              </Text>
            )}

            <FormControl isRequired size="sm" isInvalid={!!errors.deliveryDate}>
              <Flex>
                {!pv?.isDeliveryDateValid &&
                  status === OrderStatusEnum.Litigation && <TooltipError />}
                <FormLabel htmlFor="deliveryDate">
                  Date de réception des prestations
                  <Tooltip
                    label={"Cadre réservé au bénéficiaire / Date de réception des prestations"}
                  />
                </FormLabel>
              </Flex>
              <Controller
                name={"deliveryDate"}
                control={control}
                render={({ field: { value, ...rest } }) => (
                  <DatePicker
                    variant="filled"
                    selected={value}
                    size="sm"
                    inputSize="sm"
                    preventOpenOnFocus
                    {...rest}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.deliveryDate?.message?.toString()}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isRequired
              size="sm"
              isInvalid={!!errors.deliveryDelay}
            >
              <Flex>
                {!pv?.isDeliveryDelayValid &&
                  status === OrderStatusEnum.Litigation && <TooltipError />}
                <FormLabel htmlFor="deliveryDelay">
                  {t(
                    "order_pv_modal_delivery_date",
                    "Les prestations ont bien été exécutées dans les délais",
                  )}
                  <Tooltip
                    label={t(
                      "order_pv_modal_delivery_date_tooltip",
                      "Cadre réservé au bénéficiaire / Première coche",
                    )}
                  />
                </FormLabel>
              </Flex>

              <RadioGroupCustom
                direction="row"
                control={control}
                name={"deliveryDelay"}
                options={BOOLEAN_LABEL}
              />
              {deliveryDelayValue === BooleanEnum.False && (
                <Text color="semantic.warning.main" fontSize="sm" my={2}>
                  {t(
                    "order_pv_modal_warning",
                    "Attention, le PV que vous vous apprêtez à nous transmettre fait état d’un retard projet de votre fait. Ceci légitimerait de facto toute procédure de déclenchement de pénalités par le bénéficiaire sur cet ordre de service.",
                  )}
                </Text>
              )}
              <FormErrorMessage>
                {errors.deliveryDelay?.message}
              </FormErrorMessage>
            </FormControl>
            {deliveryDelayValue === BooleanEnum.False && (
              <FormControl size="sm" isInvalid={!!errors.reportClient}>
                <Flex>
                  {!pv?.isReportClientValid &&
                    status === OrderStatusEnum.Litigation && <TooltipError />}
                  <FormLabel htmlFor="reportClient">
                    {t(
                      "order_pv_modal_report_client",
                      "Le report a été demandé par le client",
                    )}
                    <Tooltip
                      label={t(
                        "order_pv_modal_report_client_tooltip",
                        "Cadre réservé au bénéficiaire / Seconde coche",
                      )}
                    />
                  </FormLabel>
                </Flex>
                <RadioGroupCustom
                  direction="row"
                  control={control}
                  name={"reportClient"}
                  options={BOOLEAN_LABEL}
                />
                {reportClientValue === BooleanEnum.False && (
                  <Text color="semantic.warning.dark" fontSize="sm" my={2}>
                    {t(
                      "order_pv_modal_warning",
                      "Attention, le PV que vous vous apprêtez à nous transmettre fait état d’un retard projet de votre fait. Ceci légitimerait de facto toute procédure de déclenchement de pénalités par le bénéficiaire sur cet ordre de service.",
                    )}
                  </Text>
                )}
                <FormErrorMessage>
                  {errors.reportClient?.message}
                </FormErrorMessage>
              </FormControl>
            )}
            <FormControl
              isRequired
              size="sm"
              isInvalid={!!errors.afterReceptionDecision}
            >
              <Flex>
                {!pv?.isAfterDescisionValid &&
                  status === OrderStatusEnum.Litigation && <TooltipError />}
                <FormLabel htmlFor="afterReceptionDecision">
                  {t(
                    "order_pv_modal_after_reception_decision",
                    "Décision après réception",
                  )}
                  <Tooltip
                    label={t(
                      "order_pv_modal_after_reception_decision_tooltip",
                      "Cadre réservé au bénéficiaire / Troisième coche",
                    )}
                  />
                </FormLabel>
              </Flex>

              <RadioGroupCustom
                control={control}
                options={AfterReceptionDecisionLabel}
                name={"afterReceptionDecision"}
              />
              {afterReceptionDecisionValue &&
                [
                  OrderAfterReceptionEnum.WithWarning,
                  OrderAfterReceptionEnum.Ajourned,
                  OrderAfterReceptionEnum.Rejected,
                ].includes(afterReceptionDecisionValue) && (
                  <Text color="semantic.warning.main" fontSize="sm" my={2}>
                    {t(
                      "order_pv_modal_warning",
                      "Attention, le PV que vous vous apprêtez à nous transmettre ne respecte pas les règles de gestion du marché et risque d'être rejeté : en effet, s’il persiste des réserves quant à la prestation, vous devez les prendre en compte puis faire signer un PV sans réserve au bénéficiaire pour procéder à la facturation. Nous vous invitons donc à réduire l’avancement de la phase pour prendre en compte les reliquats sur le projet.",
                    )}
                  </Text>
                )}
              <FormErrorMessage>
                {errors.afterReceptionDecision?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl size="sm" isInvalid={!!errors.cachet} isRequired>
              <Flex>
                {!pv?.isCachetValid &&
                  status === OrderStatusEnum.Litigation && <TooltipError />}

                <FormLabel htmlFor="cachet">
                  {t("order_pv_modal_cachet", "Le cachet du bénéficiaire")}
                  <Tooltip
                    label={t(
                      "order_pv_modal_cachet_tooltip",
                      "Cadre inférieur",
                    )}
                  />
                </FormLabel>
              </Flex>

              <RadioGroupCustom
                direction="row"
                control={control}
                name={"cachet"}
                options={BOOLEAN_LABEL}
              />
              {cachetValue === BooleanEnum.False && (
                <Text color="semantic.warning.main" fontSize="sm" mt={2}>
                  {t(
                    "order_pv_modal_cachet_warning",
                    "Attention, le PV que vous vous apprêtez à nous transmettre ne respecte pas les règles de gestion du marché et risque d'être rejeté : en effet, le cachet doit être apparent dans la zone réservé au bénéficiaire pour que le PV soit accepté par l’UGAP.",
                  )}
                </Text>
              )}
              <FormErrorMessage>{errors.cachet?.message}</FormErrorMessage>
            </FormControl>
            <FormControl size="sm" isInvalid={!!errors.signature} isRequired>
              <Flex>
                {!pv?.isSignatureValid &&
                  status === OrderStatusEnum.Litigation && <TooltipError />}
                <FormLabel htmlFor="signature">
                  {t(
                    "order_pv_modal_signature",
                    "La signature du bénéficiaire",
                  )}
                  <Tooltip
                    label={t(
                      "order_pv_modal_signature_tooltip",
                      "Cadre inférieur",
                    )}
                  />
                </FormLabel>
              </Flex>

              <RadioGroupCustom
                direction="row"
                control={control}
                name={"signature"}
                options={BOOLEAN_LABEL}
              />
              {signatureValue === BooleanEnum.False && (
                <Text color="semantic.warning.main" fontSize="sm" my={2}>
                  {t(
                    "order_pv_modal_signature_warning",
                    "Attention, le PV que vous vous apprêtez à nous transmettre ne respecte pas les règles de gestion du marché et risque d'être rejeté : en effet, la signature doit être apparente dans la zone réservé au bénéficiaire pour que le PV soit accepté par l’UGAP.",
                  )}
                </Text>
              )}
              <FormErrorMessage>{errors.signature?.message}</FormErrorMessage>
            </FormControl>
            <FormControl size="sm" isInvalid={!!errors.nameQuality} isRequired>
              <Flex>
                {!pv?.isNameQualityValid &&
                  status === OrderStatusEnum.Litigation && <TooltipError />}
                <FormLabel htmlFor="nameQuality">
                  {t(
                    "order_pv_modal_name_quality",
                    "Nom et qualité du bénéficiaire",
                  )}
                  <Tooltip
                    label={t(
                      "order_pv_modal_name_quality_tooltip",
                      "Cadre inférieur",
                    )}
                  />
                </FormLabel>
              </Flex>
              <RadioGroupCustom
                direction="row"
                control={control}
                name={"nameQuality"}
                options={BOOLEAN_LABEL}
              />
              {nameQualityValue === BooleanEnum.False && (
                <Text color="semantic.warning.main" fontSize="sm" my={2}>
                  {t(
                    "order_pv_modal_name_quality_warning",
                    "Attention, le PV que vous vous apprêtez à nous transmettre ne respecte pas les règles de gestion du marché et risque d'être rejeté : en effet, le nom et la qualité du bénéficiaire doit être apparent dans la zone réservé au bénéficiaire pour que le PV soit accepté par l’UGAP.",
                  )}
                </Text>
              )}
              <FormErrorMessage>{errors.nameQuality?.message}</FormErrorMessage>
            </FormControl>
            <FormControl size="sm">
              <FormLabel htmlFor="comment">Commentaire</FormLabel>
              <Controller
                name={"partnerComment"}
                control={control}
                rules={{ required: false }}
                render={({ field: { value, ...rest } }) => (
                  <Textarea
                    p={4}
                    fontSize="sm"
                    height={40}
                    placeholder={t(
                      "order_pv_modal_comment",
                      "Informations complémentaires sur le document",
                    )}
                    variant="filled"
                    value={value || ""}
                    {...rest}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.partnerComment?.message}
              </FormErrorMessage>
            </FormControl>
          </Flex>
        </GridItem>
        <GridItem colSpan={6} alignSelf="start">
          <Controller
            render={({ field }) => (
              <Dropzone
                accept={{ "application/pdf": [".pdf"] }}
                onChange={async (e) => {
                  const b64 = await toBase64(e);

                  field.onChange({ b64, fullFile: e });
                }}
                label={
                  <Flex
                    align="center"
                    justifyContent="start"
                    width="100%"
                    gap={6}
                  >
                    <Icon
                      as={RiDragDropLine}
                      color={"brand.blue.main"}
                      fontSize={30}
                    />
                    <Box>
                      <Heading color={"neutral.grey.900"}>
                        {fileValue?.fullFile?.name}
                      </Heading>
                      <Text size={"md"} color={"brand.blue.main"}>
                        {pv?.attachment || pv?.path ? (
                          <>
                            {t(
                              "order_pv_modal_replace_attachment",
                              "Remplacer votre pièce jointe",
                            )}
                          </>
                        ) : (
                          <>
                            {t(
                              "order_pv_modal_drop_or_browse",
                              "Déposer ou parcourir dans votre ordinateur",
                            )}
                          </>
                        )}
                      </Text>
                    </Box>
                  </Flex>
                }
              />
            )}
            control={control}
            name="file"
          />
          {fileValue ? (
            <PdfView base64={fileValue.b64} />
          ) : (
            (pv?.path || pv?.attachment) && (
              <PdfView
                path={pv?.path}
                base64={`data:application/pdf;base64,${pv?.attachment?.b64 ?? ""
                  }`}
              />
            )
          )}
        </GridItem>
      </Grid>
    </form >
  );
};
